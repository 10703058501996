import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RESTServerRoute } from '../../types/Server';
import { OCPIResponse } from '../../types/ocpi/OCPIResponse';
import {
  ActionResponse,
  OcpiEndpointDataResult,
  OCPIGenerateLocalTokenResponse,
  Ordering,
  Paging,
} from '../../types/DataResult';
import { FilterParams } from '../../types/GlobalType';
import { Constants } from '../../utils/Constants';
import { buildSortingParams } from '../utils/build-sorting-params.utils';
import { buildPagingParams } from '../utils/build-paging-params.utils';

@Injectable()
export class OcpiApiService {
  public constructor(private httpClient: HttpClient) {}

  public getOcpiEndpoints(
    params: FilterParams,
    paging: Paging = Constants.DEFAULT_PAGING,
    ordering: Ordering[] = [],
  ): Observable<OcpiEndpointDataResult> {
    const queryParams = { ...params, ...buildSortingParams(ordering), ...buildPagingParams(paging) };

    return this.httpClient.get<OcpiEndpointDataResult>(RESTServerRoute.REST_OCPI_ENDPOINTS, {
      params: queryParams,
    });
  }

  public generateEmspToken(): Observable<OCPIResponse> {
    return this.httpClient.get<OCPIResponse>(RESTServerRoute.REST_OCPI_ENDPOINT_GENERATE_LOCAL_TOKEN_EMSP);
  }

  public generateCpoToken(endpointName: string): Observable<OCPIGenerateLocalTokenResponse> {
    return this.httpClient.put<OCPIGenerateLocalTokenResponse>(
      RESTServerRoute.REST_OCPI_ENDPOINT_GENERATE_LOCAL_TOKEN,
      { name: endpointName },
    );
  }

  public registerEmspEndoint(id: string): Observable<void> {
    return this.httpClient.put<void>(RESTServerRoute.REST_OCPI_REGISTER_EMSP.replace(':id', id), {});
  }

  public registerCpoEndoint(id: string): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(RESTServerRoute.REST_OCPI_ENDPOINT_REGISTER.replace(':id', id), {});
  }

  public updateCpoEndoint(id: string): Observable<ActionResponse> {
    return this.httpClient.put<ActionResponse>(RESTServerRoute.REST_OCPI_ENDPOINT_CREDENTIALS.replace(':id', id), {});
  }

  public updateEmspEndoint(id: string): Observable<void> {
    return this.httpClient.put<void>(RESTServerRoute.REST_OCPI_UPDATE_EMSP.replace(':id', id), {});
  }
}
