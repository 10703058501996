import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OcpiApiService } from '../../api/ocpi/ocpi.api';
import { FilterParams, RestResponse } from '../../types/GlobalType';
import { OCPIRole } from '../../types/ocpi/OCPIRole';
import { OcpiEndpointDataResult, Ordering, Paging } from '../../types/DataResult';
import { Constants } from '../../utils/Constants';

@Injectable()
export class OcpiRepositoryService {
  public constructor(public readonly ocpiApi: OcpiApiService) {}

  public getOcpiEndpoints(
    params: FilterParams = {},
    paging: Paging = Constants.DEFAULT_PAGING,
    ordering: Ordering[] = [],
  ): Observable<OcpiEndpointDataResult> {
    return this.ocpiApi.getOcpiEndpoints(params, paging, ordering);
  }

  public getOcpiToken(endpointName: string, type: OCPIRole): Observable<string> {
    if (type === OCPIRole.CPO) {
      return this.ocpiApi.generateCpoToken(endpointName).pipe(map((response) => response.localToken));
    } else {
      return this.ocpiApi.generateEmspToken().pipe(map((response) => response.data['token'] as string));
    }
  }

  public registerOcpiEndpoint(endpointId: string, type: OCPIRole): Observable<void> {
    if (type === OCPIRole.CPO) {
      return this.ocpiApi.registerCpoEndoint(endpointId).pipe(
        map((response) => {
          if (response.status !== RestResponse.SUCCESS) {
            throw new Error('Failed to register CPO endpoint');
          }
        }),
      );
    } else {
      return this.ocpiApi.registerEmspEndoint(endpointId);
    }
  }

  public updateOcpiEndpoint(endpointId: string, type: OCPIRole): Observable<void> {
    if (type === OCPIRole.CPO) {
      return this.ocpiApi.updateCpoEndoint(endpointId).pipe(
        map((response) => {
          if (response.status !== RestResponse.SUCCESS) {
            throw new Error('Failed to update CPO endpoint');
          }
        }),
      );
    } else {
      return this.ocpiApi.updateEmspEndoint(endpointId);
    }
  }
}
